import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StaticImage } from 'gatsby-plugin-image'

const HistoryScreen = () => {
  const { t } = useTranslation()
  return (
    <div className="HistoryScreen">
      <section id="about-page">
        <Container>
          <Row>
            <Col lg="6">
              <div className="about-img">
                <StaticImage
                  src="../../assets/images/gracie-family.webp"
                  width={540}
                  alt="gracie-family"
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="about-text">
                <h3 className="title">
                  {t('pages.history.gracieFamily.title')}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('pages.history.gracieFamily.content'),
                  }}
                ></p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="about-text">
                <h3 className="title">{t('pages.history.ufc.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('pages.history.ufc.content'),
                  }}
                ></p>
              </div>
            </Col>
            <Col lg="6">
              <div className="about-img">
                <StaticImage
                  src="../../assets/images/ufc-royce-gracie.webp"
                  width={540}
                  alt="royce-gracie-gracie"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="about-img">
                <StaticImage
                  src="../../assets/images/bjj-modern.webp"
                  width={540}
                  alt="modern-bjj-competition-scene"
                />
              </div>
            </Col>
            <Col lg="6">
              <h3 className="title">{t('pages.history.bjj.title')}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('pages.history.bjj.content'),
                }}
              ></p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default HistoryScreen
