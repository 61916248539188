import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import PageHero from '../components/PageHero'
import HistoryScreen from '../components/Screens/HistoryScreen'

const HistoryPage = () => {
  const { t } = useTranslation()

  return (
    <Layout title={t('pages.history.hero.title')}>
      <PageHero title={t('pages.history.hero.title')} />
      <HistoryScreen />
    </Layout>
  )
}

export default HistoryPage
